$theme-colors: (
  "primary": #1B325F,
  "secondary": #0344CA,
  "info": #0284C7,
  "success": #65A30D,
  "danger": #DC2626,
  "warning": #FBBF24,
  "light": #FAFAFA,
  "dark": #171717,
  "primaryM": #1B325F,
  "secondaryM": #595858,
  "infoM": #878787,
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.object-cover {
  object-fit: cover;
}